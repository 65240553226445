@import '@fontsource-variable/public-sans';
@import '@fontsource-variable/nunito-sans';

@font-face {
  font-family: 'Nunito Sans';
  src: url('../src/fonts/NunitoSans-Italic-VariableFont_YTLC,opsz,wdth,wght.ttf');
  font-style: normal;
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Nunito Sans', sans-serif;
}
